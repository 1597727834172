<template>
  <v-container class="warning-area-exceed-account pa-0">
    <v-alert
      class="alert-style mb-0"
      prominent
      text-color="#4F430C"
      color="#FFF2B0"
    >
      <v-row align="center" no-gutters>
        <v-icon class="mr-2 pl-2 pr-2" color="#A68B08">mdi-alert</v-icon>
        <v-col class="grow">
          {{ $t('message_area_exceeded') }}
        </v-col>
        <v-col class="shrink pr-7">
          <v-btn
            small
            class="text-none text-button-action"
            @click="openDialogContact"
          >
            {{ $t('get_in_touch') }}
          </v-btn>
        </v-col>
        <!-- <v-col class="shrink">
          <v-btn
            small
            class="text-none text-button-action"
            @click="openDialogReleaseArea"
          >
            {{ $t('account.release_area') }}
          </v-btn>
        </v-col> -->
      </v-row>
    </v-alert>

    <action-dialog
      :dialog="dialogContact"
      :text="$t('which_channel_to_contact')"
      :text-cancel="$t('commercial')"
      :text-confirm="$t('relationship')"
      :cancel="contactCommercial"
      :confirm="contactRelationship"
      :icon-cancel="''"
      :icon-confirm="''"
      color-cancel="#39af49"
      color-confirm="#39af49"
    />

    <dialog-release-area
      v-if="dialogReleaseArea"
      @close="closeDialogReleaseArea"
    />
  </v-container>
</template>

<script>
import ActionDialog from '@/components/Dialogs/ActionConfirmationDialog.vue'
import DialogReleaseArea from '@/pages/fazendas/DialogReleaseArea.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MessageAreaExceededAccount',

  data() {
    return {
      dialogReleaseArea: false,
      dialogContact: false,
    }
  },

  components: {
    ActionDialog,
    DialogReleaseArea,
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    accountName() {
      return this.currentUser?.name
    },
    contractedArea() {
      return this.currentUser?.total_area_account
    },
    commercialWhatsapp() {
      return (
        process.env.VUE_APP_COMMERCIAL_WHATSAPP +
        this.$t('contato.msg_whatsapp_limit_area_exceeded', [
          this.accountName,
          this.contractedArea,
        ])
      )
    },
    relationshipWhatsapp() {
      return (
        process.env.VUE_APP_RELATIONSHIP_WHATSAPP +
        this.$t('contato.msg_whatsapp_limit_area_exceeded', [
          this.accountName,
          this.contractedArea,
        ])
      )
    },
  },

  methods: {
    // openDialogReleaseArea() {
    //   this.dialogReleaseArea = true
    // },
    closeDialogReleaseArea() {
      this.dialogReleaseArea = false
    },
    openDialogContact() {
      this.dialogContact = true
    },
    contactCommercial() {
      window.open(this.commercialWhatsapp).focus()
      this.dialogContact = false
    },
    contactRelationship() {
      window.open(this.relationshipWhatsapp).focus()
      this.dialogContact = false
    },
  },
}
</script>

<style lang="scss" scoped>
.warning-area-exceed-account {
  height: 60px;

  .alert-style {
    z-index: 2;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4f430c;
    border-radius: 4px !important;
    border: 1px solid !important;
    border-color: #ede09d !important;
  }

  .text-button-action {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: black;
    letter-spacing: 0;
  }
}
</style>
